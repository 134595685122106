// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyB-ONlaZd_BGT26RsWhV-qxa66R8LwVPkg",
    authDomain: "haisoft-landingpage.firebaseapp.com",
    projectId: "haisoft-landingpage",
    storageBucket: "haisoft-landingpage.appspot.com",
    messagingSenderId: "842386565973",
    appId: "1:842386565973:web:631c17913256b27cce9ddc",
    measurementId: "G-4DCHX57LG5"
  }
};

