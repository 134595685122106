import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

interface datastr {
  name:string, 
  email:string,
  message:string
};

@Injectable({
  providedIn: 'root'
})
export class LandingService {

  constructor(private db:AngularFirestore) { }


  huevosalex(data:datastr):Promise<any>{
    return this.db.collection("correos").add(data);
  }
}
