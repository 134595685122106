import { Component, OnInit } from '@angular/core';
import { LandingService } from 'app/services/landing.service';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  focus: any;
  focus1: any;
  name: string="";
  email: string="";
  message: string="";

  constructor(private service:LandingService) { }

  ngOnInit() {}

  onClickEmail(){
    console.log(this.name,this.email,this.message)
    if (this.name == "" && this.email == "" && this.message == ""){
      alert("Por favor, ingresa un mensaje.");
    }
    else{
      const data = {
        name:this.name, 
        email:this.email, 
        message:this.message
      };
      this.service.huevosalex(data)
      .then((res:any)=>{
        console.log(res)
        this.name = "";
        this.email = "";
        this.message = "";
      })
      .catch((error:any)=>{
        console.log(error);
      })
    }
  }

}
